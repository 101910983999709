import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({direction, name, className, isDisabled, onClick}) => {
    return (
        <div className={`chev-${direction} pt-5`}>
            <button className={`cars-button ${className}`} disabled={isDisabled}
                    name={name} onClick={onClick}> </button>
        </div>
    );
};

Chevron.propTypes = {
    direction: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    name: PropTypes.string
};

export default Chevron;