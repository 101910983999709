import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {userAuthRequest} from "../../../utils/backend";
import SocialLoginButton from "../SocialButton/SocialLoginButton";
import FacebookIcon from '../../../assets/facebook_circle-512.png';
import {loginResponseHandler} from "../../../services/UserService/UserService";

const FacebookLoginButton = props => {

    const responseFacebook = (response) => {
        userAuthRequest.post('/user/login/facebook', {
            "id": response.id,
            "accessToken": response.accessToken
        }).then(res => {
            loginResponseHandler(res);
            props.history.push('/user/payments')

        }).catch(err => console.log(err))
    };

    return (
        <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_API_KEY}
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
                <SocialLoginButton icon={FacebookIcon} onClick={renderProps.onClick} disabled={renderProps.disabled}/>
            )}
        />
    );
};

FacebookLoginButton.propTypes = {
    history: PropTypes.object
};

export default FacebookLoginButton;