import React from 'react';
import {Switch, Route} from "react-router-dom";
import {StripeProvider} from "react-stripe-elements";
import StripeAddCardHOC from "./StripeAddCard/StripeAddCardHOC";
import {userAuthRequest} from "../../utils/backend";
import NotificationService from "../../services/NotificationService/NotificationService";
import StripePayments from "./StripePayments/StripePayments";

const MobileStripePayments = props => {

    const handleCardAdded = (paymentMethod, showLoading) => {

        userAuthRequest.post('/card', paymentMethod, {
            headers: {
                "Content-Type": 'text/plain',
                "Authorization": sessionStorage.getItem('authtoken')
            }
        }).then(res => {
            NotificationService.notify('success', 'Card added successfully', '')
            showLoading(false)
        });
    };

   const handlePaymentComplete = (paymentIntentId,amount) => {
        userAuthRequest.post('/payment', {
            paymentIntentId: paymentIntentId,
            amount:amount
        }, {
            headers: {
                authorization: sessionStorage.getItem('authtoken')
            }
        }).then(res => {
            console.log(res)
            alert(res.data)
        });
    };



    return (
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
            <div>
                <Switch>
                    <Route path="/stripe/card" render={(props) => <StripeAddCardHOC onCardAdded={handleCardAdded}/>}/>
                    <Route path="/stripe/confirm" render={(props) => <StripePayments onPaymentComplete={handlePaymentComplete}/>}/>
                </Switch>
            </div>
        </StripeProvider>
    );
};

MobileStripePayments.propTypes = {};

export default MobileStripePayments;