import React, {Component} from 'react';
import LoginForm from "../LoginForm/LoginForm";
import {Modal} from "react-bootstrap";
import NavigationBar from "../NavigationBar/NavigationBar";
import CarouselSection from './CarouselSection/CarouselSection';
import './HomePage.css';
import AccessPointSection from "./AccessPointSection/AccessPointSection";
import ChargeInfoSection from "./ChargeInfoSection/ChargeInfoSection";
import SupportIcon from "../../assets/headset.svg";
import ChevronRight from '../../assets/chevron-right.svg'
import Footer from "../Footer/Footer";
import InfoBoxesSection from "./InfoBoxesSection/InfoBoxesSection";
import RegistrationForm from "../RegistrationForm/RegistrationForm";
import {loginResponseHandler} from "../../services/UserService/UserService";
import config from "../../config";
import {userAuthRequest} from "../../utils/backend";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoginModalVisible:false,
            isRegistrationModalVisible:false
        }
    }

    handleShowAndCloseLoginModal = () => {
        this.setState({isLoginModalVisible: !this.state.isLoginModalVisible})
    };

    handleShowAndCloseRegistrationModal = () => {
        this.handleShowAndCloseLoginModal();
        this.setState({isRegistrationModalVisible: !this.state.isRegistrationModalVisible})
    };

    attemptTwitterLogin = () => {
        let tokensMatch = config.validators.twitterOauthParameters.exec(window.location.href);

        if (tokensMatch) {

            const {groups: {oauth_token, oauth_verifier}} = tokensMatch;

            userAuthRequest.post('/user/login/twitter', {
                'oauthToken': oauth_token,
                'oauthVerifier': oauth_verifier.substr(0,  oauth_verifier.length - 2)
            }).then(res => {
                    loginResponseHandler(res);
                    this.props.history.push('/user/payments')
                    return true;
                }
            ).catch(err => {
                console.log(err.response)
            })
        }

        return false;
    };

    render() {
        config.validators.twitterOauthParameters.exec(window.location.href) && this.attemptTwitterLogin()
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <NavigationBar onLoginClick={this.handleShowAndCloseLoginModal}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 p-0">
                      <CarouselSection/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 p-0">
                        <InfoBoxesSection/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 p-0">
                        <AccessPointSection/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 p-0">
                        <ChargeInfoSection/>
                        <div className="col-12 p-0 sup-wrapper">
                            <div className="offset-2 col-6  pt-5 m-auto">
                                <div className="d-flex ml-5">
                                    <div className="ml-5 pr-4">
                                        <img className="" src={SupportIcon} alt=""/>
                                    </div>
                                    <div>
                                        <p>NEED MORE INFORMATION?</p>
                                        <p>WE STRIVE TO PROVIDE OUR CUSTOMERS WITH THE HIGHEST LEVEL OF
                                            <span
                                                className="support-info"> SUPPORT
                                                <img className="chevron-right ml-1"
                                                     src={ChevronRight} alt=""/>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 p-0">
                        <Footer/>
                    </div>
                </div>

                <Modal show={this.state.isLoginModalVisible}
                       onHide={this.handleShowAndCloseLoginModal}>

                    <LoginForm history={this.props.history}
                               onRegisterClick={this.handleShowAndCloseRegistrationModal}

                    />
                </Modal>

                <Modal show={this.state.isRegistrationModalVisible}
                       onHide={this.handleShowAndCloseRegistrationModal} >
                    <RegistrationForm onRegistrationComplete={this.handleShowAndCloseRegistrationModal} />
                </Modal>
            </div>
        );
    }
}

export default HomePage;