import React from 'react';
import PropTypes from 'prop-types';

const SelectField = ({nameAndId, label, options, defaultValue, onChange}) => {

    return (
        <div className="col-12 d-flex  p-3 select-wrapper">
            <label className="labels col-3" htmlFor={nameAndId}>{label}</label>
            <select className="select-field" onChange={onChange} name={nameAndId} id="prof-select" defaultValue={defaultValue}>
                <option hidden value={defaultValue}>{defaultValue}</option>
                {options.map(opt => (
                    <option key={opt} value={opt}>{opt}</option>
                ))}
            </select>
        </div>
    );
};

SelectField.propTypes = {
    nameAndId: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func
};

export default SelectField;