import {userAuthRequest} from "../../utils/backend";
import UserDataService from "../storageService/UserDataService";
import sessionStorageService from "../storageService/LocalStorageService";

export const loginUserRequest = (email, password) => {
    return  userAuthRequest.post('/user/login', {
        "email": email,
        "password": password
    })
};

export const loginResponseHandler = (response) => {
    UserDataService.setUserData(response.data);
    let tokens = {
        accessToken: response.headers.authorization,
        refresh: response.headers.refresh
    };
    sessionStorageService.setTokens(tokens);
};