import React from 'react';
import './App.css';
import {Route, HashRouter as Router} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import ForgottenPasswordPage from "./components/ForgottenPasswordPage/ForgottenPasswordPage";
import LoggedUserMainPage from "./components/LoggedUserMainPage/LoggedUserMainPage";
import SubscriptionPlans from "./components/SubscriptionPlans/SubscriptionPlans";
import {NotificationContainer} from 'react-notifications';
import MobileStripePayments from "./components/MobileStripePayments/MobileStripePayments";

function App() {

    return (
        <div className="App">
            <Router>
                <Route exact path="/" component={HomePage}/>
                <Route  path="/user" component={LoggedUserMainPage} />
                <Route path="/forgottenPassword" component={ForgottenPasswordPage} />
                <Route path="/subscriptions" component={SubscriptionPlans} />
                <Route path="/stripe" component={MobileStripePayments} />
            </Router>

            <NotificationContainer/>
        </div>
    );
}

export default App;
