import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({nameAndId, type, label, defaultValue, onChange}) => {
    return (
            <div className="col-12 d-flex  p-3">
                <label className="labels col-3" htmlFor={nameAndId}>{label}</label>
                <input onChange={onChange} className="input-field" defaultValue={defaultValue} type={type} name={nameAndId} id={nameAndId}/>
            </div>
    );
};

InputField.propTypes = {
    nameAndId: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func
};

export default InputField;