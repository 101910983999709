import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from "react-bootstrap";

const CarDeleteConfirmModal = ({isVisible, onClose, onDeletionConfirm}) => {
    return (
        <Modal show={isVisible}
               onHide={onClose}
               centered

        >
            <Modal.Header closeButton>
                <Modal.Title>Are you sure ?</Modal.Title>
            </Modal.Header>

            <Modal.Footer>
                <Button onClick={onClose} variant="primary">Close</Button>
                <button onClick={onDeletionConfirm} className="btn btn-danger">Delete
                </button>
            </Modal.Footer>
        </Modal>
    );
};

CarDeleteConfirmModal.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onCarDeletionConfirm: PropTypes.func
};

export default CarDeleteConfirmModal;