import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import NextonLogo from '../../assets/LOGO.svg'
import UserIcon from '../../assets/user_icon_grey.svg'
import './NavigationBar.css';
import UserDataService from "../../services/storageService/UserDataService";
import LocalStorageService from "../../services/storageService/LocalStorageService";
import config from "../../config";

const NavigationBar = ({onLoginClick}) => {
    const getUserName = () => {
        const data = UserDataService.getUserData();
        return data.firstName
    };

    const getPage =() => {
        if (sessionStorage.getItem('active')) {
            return sessionStorage.getItem('active')
        } else  {
            return  config.pageNames.PAYMENTS
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 pl-0 pr-0">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <Link className="navbar-brand" to="/#">
                            <img src={""} alt=""/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nexton-logo-li">
                                    <Link to="">  <img className="pt-2" src={NextonLogo} alt=""/></Link>
                                </li>
                                {LocalStorageService.getAccessToken()
                                    ? <li className="nav-item">
                                        <Link to={`/user/${getPage()}`} className="nav-link"> <b>MY MENU</b> </Link>
                                    </li>
                                    : null}
                                <li className="nav-item">
                                    <Link to="" className="nav-link"> <b>ABOUT</b> </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="" className="nav-link"> <b> CONTACTS </b> </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/subscriptions" className="nav-link"> <b> SUBSCRIPTION PLANS</b> </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="" className="nav-link"><b>STATIONS</b> </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="" className="nav-link"><b> SUPPORT</b> </Link>
                                </li>
                            </ul>
                            <div className="form-inline my-2 my-lg-0">

                                {LocalStorageService.getAccessToken() ? <span className="pl-2">      <img style={{width: '0.9vw'}} src={UserIcon} alt=""/> Hello, {getUserName()}</span> :
                                    <button className="sign-in-btn" onClick={onLoginClick}><img style={{width: '0.9vw'}} src={UserIcon} alt=""/> Sign in
                                    </button>
                                }
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

NavigationBar.propTypes = {
    onLoginClick: PropTypes.func
};

export default NavigationBar;
