import React, {Component} from 'react';
import UserIcon from '../../assets/user_icon_payments.svg';
import ChangePasswordIcon from '../../assets/change_password_icon.svg';
import './UserProfile.css';
import InputField from "./InputField";
import SelectField from "./SelectField";
import {backendCommunicator} from "../../utils/backend";
import Countries from './Countries';
import LanguageDropdown from "./LanguageDropdown";
import {Modal, Spinner} from "react-bootstrap";
import UpdatePasswordForm from "./UpdatePasswordForm/UpdatePasswordForm";
import DatePicker from "react-date-picker";
import moment from "moment";
import ChevronDown from '../../assets/chevron-down-solid.svg'
import NotificationService from "../../services/NotificationService/NotificationService";
import config from "../../config";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            updatedProfile: {},
            updateButtonDisabled: true,
            isUpdatePasswordVisible: false,
            birthdate: '',
            loading: false
        };
    }


    async componentDidMount() {
        this.props.onActiveLinkChange(config.pageNames.PROFILE)
        this.setState({
            loading: true
        })
        const response = await backendCommunicator.get('/profile');
        this.setState({
            profile: response.data,
            birthdate: new Date(response.data.birthdate),
            loading: false
        });
        document.querySelector('select').disabled = true
    }

    handleProfileChange = (event) => {
        let updatedProfile = this.state.profile;
        if (event.target) {
            let fieldName = event.target.name;
            let fieldValue = event.target.value;
            updatedProfile[fieldName] = fieldValue;

            this.setState({updatedProfile: updatedProfile, updateButtonDisabled: false});

        } else {
            this.setState({
                birthdate: event
            });
            updatedProfile['birthdate'] = moment(event).format("YYYY-MM-DD")
            this.setState({updatedProfile: updatedProfile, updateButtonDisabled: false});
        }
    };

    handleProfileUpdateRequest = (event) => {
        event.preventDefault();
        let profileData = this.state.updatedProfile;
        backendCommunicator.put('/profile', profileData).then(res => {
            this.setState({profile: res.data, updateButtonDisabled: true});
            this.props.onUserDataChange(res.data)
            NotificationService.notify('success', 'Profile updated successfully!')

        }).catch(err => console.log(err))
    };

    handleOpenAndCloseUpdatePasswordModal = () => {
        this.setState({isUpdatePasswordVisible: !this.state.isUpdatePasswordVisible})
    };

    render() {
        const profile = this.state.profile;
        return (
            <div className="col-12 p-0 d-flex">
                <div className="col-3 pre-content text-center">
                    <div className="text-center">
                        <img src={UserIcon} alt="" />
                        <h3 className="payments-heading">PROFILE</h3>
                        <p>{profile.email}</p>
                    </div>
                    <button onClick={this.handleOpenAndCloseUpdatePasswordModal} className="mt-5 up-trigger-btn">
                        <img src={ChangePasswordIcon} alt="" />
                        <span className="pl-2 text-blue">
                            Change Password
                        </span>
                    </button>
                </div>

                <div className="col-10 p-0 mt-5">
                    {(() => {
                        if(this.state.loading) {
                            return <div className="col-2  m-auto"> <Spinner animation="border" variant="primary"/></div>
                        } else {
                            return (
                                <form className="col-9 m-auto">

                                    <InputField onChange={this.handleProfileChange} label="First Name" nameAndId="firstName"
                                                type="text" defaultValue={profile.firstName} />
                                    <InputField onChange={this.handleProfileChange} label="Last Name" nameAndId="lastName"
                                                type="text" defaultValue={profile.lastName} />

                                    <div className="col-12 p-3 d-flex ">
                                        <label className="labels col-3">Date of birth</label>

                                        <DatePicker
                                            locale={"EN"}
                                            clearIcon={null}
                                            onChange={this.handleProfileChange}
                                            className="profile-calendar"
                                            format="dd MMM yyyy"
                                            calendarIcon={<img src={ChevronDown} alt="" />}
                                            value={this.state.birthdate}
                                        />
                                    </div>

                                    <SelectField label="Country" nameAndId="country" defaultValue={profile.country}
                                                 options={Countries.countries} onChange={this.handleProfileChange} />
                                    <LanguageDropdown label="Language" defaultValue={"English"}
                                                      nameAndId="language" onChange={this.handleProfileChange} />

                                    <SelectField label="System" nameAndId="system" defaultValue={profile.system}
                                                 options={["IMPERIAL", "METRIC"]} onChange={this.handleProfileChange} />

                                    <button onClick={this.handleProfileUpdateRequest} disabled={this.state.updateButtonDisabled} className="profile-update-btn p-0"
                                            type="submit">UPDATE
                                    </button>
                                </form>
                            ) }
                    })()}
                </div>



                <Modal show={this.state.isUpdatePasswordVisible}
                       onHide={this.handleOpenAndCloseUpdatePasswordModal}
                       centered
                       dialogClassName="update-form-modal"
                >
                    <Modal.Body>
                        <UpdatePasswordForm closeModal={this.handleOpenAndCloseUpdatePasswordModal} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default UserProfile;