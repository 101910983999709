import React from 'react';
import PlansIcon from "../../../assets/icon-1.svg";
import EVIcon from "../../../assets/ev-icon.svg";
import CMIcon from "../../../assets/cm-icon.svg";
import BPIcon from "../../../assets/bp-icon.svg";
import InfoBox from "./InfoBox/InfoBox";

const InfoBoxesSection = () => {
    return (
        <div className="d-lg-flex d-sm-block ">
            <InfoBox headingText="SUBSCRIPTION PLANS" icon={PlansIcon}/>
            <InfoBox headingText="ELECTRIC VEHICLES" icon={EVIcon}/>
            <InfoBox headingText="CHARGING MAP" icon={CMIcon}/>
            <InfoBox headingText="BUSINESS PROGRAM" icon={BPIcon}/>
        </div>
    );
};

export default InfoBoxesSection;