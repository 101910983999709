import React from 'react';
import PropTypes from 'prop-types'
import './VerticalNavItem.css'
import config from "../../config";
import {Link} from "react-router-dom";

const VerticalNavItem = ({name, icon, text, to}) => {
    return (
        <Link to={to} className={`nav-link navs d-flex ${sessionStorage.getItem('active')=== name && 'active'}`}>
            <div className="nav-icon">
                <img className="pl-4 pr-4 " src={icon} alt=""/>
            </div>
            <div className="pt-2">{text}</div>
        </Link>
    );
};

VerticalNavItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
};

export default VerticalNavItem;