import React from 'react';
import './Footer.css'
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import FooterLogo from '../../assets/footer_logo.svg'
import FooterMailLogo from '../../assets/footer_mail_icon.svg'
import FacebookLogo from '../../assets/Shape 2.svg'
import YoutubeLogo from '../../assets/Shape 12.svg'
import TwitterLogo from '../../assets/twitter-icon.svg'
import InstagramLogo from '../../assets/Shape 11.svg'
import Divider from '../../assets/footer-line.svg'

const Footer = props => {
    return (
        <div className="container-fluid footer-container">
            <div className="row pt-5">
                <div className="col-10 offset-2">
                    <img src={FooterLogo} alt=""/>
                    <p className="footer-text">We believe in our clean feature and clean environment.</p>
                </div>

            </div>
            <div className="row pt-4">
                <div className="col-10 offset-2 d-flex">
                    <div className="col-3 footer-text">
                        <h4 className="headquarters-text">HEADQUARTERS</h4>

                        <p className="pt">Collection Business Center, Dreischeibenhaus, Düsseldorf, </p>
                        <p>Phone: +49 211 64 155 086, +49 211 88 250 210, +49 160 885 12 50</p>


                        <p>Fax: +49 211 88 250 25</p>
                    </div>
                    <div className="col-2 pl-3">
                        <Link className="footer-link" to="/"><b>HOME</b></Link>
                        <p><b>CHARGING</b></p>
                        <p><b>SOLIS TREE</b></p>
                        <p><b>COMFORT AND SAFETY</b></p>
                        <p><b>SUBSCRIPTION PLAN</b></p>
                    </div>
                    <div className="col-2">
                        <p><b>SUPPORT</b></p>
                        <p><b>FOR BUSINESS</b></p>
                        <p><b>ABOUT NEXTON</b></p>
                        <p><b>NEWS/EVENTS</b></p>
                        <p><b>CONTACT US</b></p>
                    </div>
                    <div className="col-4">
                        <h4><span className="headquarters-text">WEEKLY NEWSLETTER</span> <span className="pl-2"> <img style={{width:'2vw'}} src={FooterMailLogo} alt=""/></span></h4>
                        <p style={{width:'400px'}}>Subscribe to Our Newsletter to get important news, amazing offers and inside scoops.</p>
                        <br/>
                        <input className="email-input" type="email" placeholder="Your Email"/>
                        <button className="newsletter-subscribe-button">SUBSCRIBE</button>

                    </div>

                </div>
            </div>
            <div className="row pt-5 text-center ">
                <div className="col-12">

                    <div className="col-12 footer-socials">
                        <img src={Divider} alt=""/>
                        <a className="pl-4 pr-4" href="/#"> <img src={FacebookLogo} alt=""/> </a>
                        <a className="pl-4 pr-4 " href="https://www.youtube.com/channel/UCqNliinDyMe7ACYMBrqlZ8g" target="_blank"> <img src={YoutubeLogo} alt=""/> </a>
                        <a className="pl-4 pr-4" href="/#"> <img src={TwitterLogo} alt=""/> </a>
                        <a className="pl-4 pr-4" href="/#"> <img src={InstagramLogo} alt=""/> </a>
                        <img src={Divider} alt=""/>
                    </div>
                </div>
            </div>
            <div className="row mt-5 text-center">
                <div className="col-12">
                    <p><b className="headquarters-text">NEXTON</b><span className="footer-text"> © 2020 All Right Reserved</span></p>
                </div>
                <div className="col-8 m-auto pb-5 footer-text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab animi aperiam assumenda at dolor
                    dolores ducimus excepturi, expedita, facere harum natus nesciunt nobis perspiciatis provident
                    quibusdam rem, veritatis vero! Quam? Architecto assumenda, corporis, distinctio enim expedita laboriosam magnam omnis quae qui
                    quidem soluta suscipit. Architecto beatae consequuntur cumque ea fugit harum ipsa iste iusto
                    maiores minima neque nulla quos, sed.
                </div>

            </div>
        </div>
    );
};

Footer.propTypes = {

};

export default Footer;