import React, {Component} from 'react';
import './SubscriptionPlans.css'
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";
import {backendCommunicator} from "../../utils/backend";
import Plan from "./Plan/Plan";
import {Modal, Button, Spinner} from "react-bootstrap";
import SessionstorageService from "../../services/storageService/LocalStorageService";
import LoginForm from "../LoginForm/LoginForm";
import SingleStar from '../../assets/STAR.png'
import DoubleStar from '../../assets/DOUBLESTAR.png'
import TripleStar from '../../assets/TRISTAR.png'


class SubscriptionPlans extends Component {
    state = {
        plans: {},
        openSubscribeModal: false,
        selectedPlan: {},
        isLoginModalVisible: false,
        loading: false
    };

    componentDidMount() {
        this.setState({loading: true})
        backendCommunicator.get('/plan').then(res => {
            this.setState({plans: res.data, loading:false})
        }).catch(err => {
            console.log(err)
        })
    }

    openAndCloseSubscribeModal = () => {
        this.setState({openSubscribeModal: !this.state.openSubscribeModal})
    };

    handleSubscribeToPlan = (plan) => {
        if (SessionstorageService.getAccessToken()) {
            this.setState({selectedPlan: plan});
            this.openAndCloseSubscribeModal();
        } else {
            this.handleShowAndCloseLoginModal();
        }

    };
    handleShowAndCloseLoginModal = () => {
        this.setState({isLoginModalVisible: !this.state.isLoginModalVisible})
    };

     getStarType = (ordinal) => {
        switch (ordinal) {
            case 1:
                return SingleStar;
            case 2:
                return DoubleStar;
            case 3:
                return TripleStar;
            default:
                break;
        }
    };

    render() {
        const plans = this.state.plans;
        return (
            <div className="container-fluid">
                <div className="row sp-background">
                    <div className="col-12 pr-0 pl-0 ">
                        <NavigationBar onLoginClick={this.handleShowAndCloseLoginModal}/>
                        <div className="col-12 d-flex font-wrap">
                            <div className="col-4 heading-wrapper">
                                <p className="heading-title">Subscription Plans</p>
                                <p className="heading-body">Introducing the NEXTON EV CHARGING STATION, a new concept in
                                    charging service,
                                    with the most advanced innovations brought together into one complete package.
                                    Featuring smart rapid charging, renewable energy.
                                </p>
                            </div>
                            <div className="row ">
                                {(()=>{
                                    if(this.state.loading) {
                                       return <div className="col-10 spinner-sp">
                                           <Spinner animation={"border"} variant={"primary"}/>
                                       </div>
                                    } else {
                                       return <div className="col-8 plans-row plans-wrapper d-flex">

                                           {Object.values(plans)
                                               .sort((a, b) => a.planOrdinal - b.planOrdinal)
                                               .map(plan => (
                                                   <Plan key={plan.id}
                                                         planName={plan.planName}
                                                         planPrice={plan.initialAmount}
                                                         energyPrice={plan.amountPerKwh.toFixed(2)}
                                                         icon={this.getStarType(plan.planOrdinal)}
                                                         onClick={() => {
                                                             this.handleSubscribeToPlan(plan)
                                                         }}
                                                   />
                                               ))}
                                       </div>
                                    }
                                })()}
                            </div>

                        </div>
                    </div>

                </div>

                <Modal show={this.state.openSubscribeModal}
                       onHide={this.openAndCloseSubscribeModal}
                >
                    <Modal.Body>
                        You subscribed to plan {this.state.selectedPlan.planName} and payed {this.state.selectedPlan.initialAmount} €.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.openAndCloseSubscribeModal} variant="secondary">Close</Button>
                        <Button onClick={this.openAndCloseSubscribeModal} variant="primary">OK</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.isLoginModalVisible}
                       onHide={this.handleShowAndCloseLoginModal} >

                    <LoginForm history={this.props.history}
                    />
                </Modal>

                <div className="row">
                    <Footer/>
                </div>
            </div>
        );
    }
};

export default SubscriptionPlans;