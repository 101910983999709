import React from 'react';
import UserIcon from "../../assets/user_icon.svg";
import PaymentsIcon from "../../assets/bill_icon.svg";
import CalendarIcon from "../../assets/calendar_icon.svg";
import SettingsIcon from "../../assets/settings_icon.svg";
import LogoutIcon from "../../assets/logout_icon.svg";
import VerticalNavItem from "./VerticalNavItem";
import UserDataService from "../../services/storageService/UserDataService";
import config from "../../config";
import LocalStorageService from "../../services/storageService/LocalStorageService";

const VerticalNavbar = props => {
    const logout = () => {
        LocalStorageService.deleteTokens();
        UserDataService.removeUserData();
        sessionStorage.clear();
        window.location.href = '/'
    };

    return (
        <div className="nav flex-column nav-pills bgr  navLinkContainer">

            <VerticalNavItem to={"/user/profile"}
                             icon={UserIcon}
                             name="profile"
                             text="PROFILE"/>

            <VerticalNavItem to={"/user/payments"}
                             icon={PaymentsIcon}
                             name="payments"
                             text="PAYMENTS"/>

            <VerticalNavItem to={"/user/subscription"}
                             icon={CalendarIcon}
                             name="subscription"
                             text="MY SUBSCRIPTION"/>

            <VerticalNavItem to={"/user/preferences"}
                             icon={SettingsIcon}
                             name="preferences"
                             text="PREFERENCES"/>

            <div className="logout-btn-wrapper">

                <button onClick={logout} className="logout-btn nav-link navs"><span className="pr-2"><img
                    src={LogoutIcon} alt=""/></span> LOG OUT
                </button>
            </div>
        </div>

    );
};

export default VerticalNavbar;