import React, {Component} from 'react';
import './UserSessions.css';
import {backendCommunicator} from "../../utils/backend";
import PaymentHeadingLogo from '../../assets/payment_heading_logo.svg';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-date-picker';
import Calendar from '../../assets/icon_calendar.svg'
import moment from "moment";
import NotificationService from "../../services/NotificationService/NotificationService";
import {Spinner} from "react-bootstrap";
import config from "../../config";

class UserSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            dateFrom: '',
            dateTo: '',
            loading: false
        };
    }

    async componentDidMount() {
        this.props.onActiveLinkChange(config.pageNames.PAYMENTS)
        this.setState({loading: true})
        let response = await backendCommunicator.get('/charge/history');
        this.setState({data: response.data, loading: false});

    }

    handleDateChange = (event, dateType) => {
        if (event === null) {
            if (dateType === 'dateFrom') {
                this.setState({dateFrom: ''})
            } else {
                this.setState({dateTo: ''})
            }

            return
        }

        let date = new Date(event.toLocaleString());

        if (dateType === 'dateFrom') {
            this.setState({dateFrom: date})
        } else {
            this.setState({dateTo: date})
        }
    };

    filterSessions = (item) => {
        let dateFrom = this.state.dateFrom && this.state.dateFrom.getTime();
        let dateTo = this.state.dateTo && this.state.dateTo.getTime();
        let sessionDate = new Date(item.sessionEndDateTime).getTime();

        if (dateFrom && dateTo) {

            if (dateFrom < dateTo) {
                return sessionDate >= dateFrom
                    && sessionDate <= dateTo
            } else {
                return sessionDate <= dateFrom
                    && sessionDate >= dateTo
            }

        } else if (!dateFrom && dateTo) {

            return sessionDate <= dateTo

        } else if (dateFrom && !dateTo) {

            return sessionDate >= this.state.dateFrom
        } else {
            return item
        }
    };

    handleReceiptDownload = (url) => {
        let hash = url.substring(url.lastIndexOf('/') + 1);
        if (hash !== 'null') {
            window.open(
                url,
                '_blank'
            );
        } else {
            NotificationService.notify('error', 'Please excuse us for the inconvenience.', 'Contact our support for more information.')
        }
    };

    formatTime = (time) => {
        return moment(time).format('DD.MM.YYYY - HH:mm')
    };

    render() {

        const data = this.state.data;
        let haveDataToRender = Object.values(data).length > 0;

        let dataTable = <table className="table table-hover table-sm ">
            <thead>
            <tr className="thead-row">
                <th>Station name</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Price</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(data).filter(item => this.filterSessions(item)).map((item) => (
                <tr key={data.indexOf(item)}
                    onClick={() => this.handleReceiptDownload(item.receiptBaseUrl)}
                    className="datarow">
                    <td className="station-name-data">{item.stationAddress}</td>
                    <td className="date-data">{this.formatTime(item.sessionEndDateTime)}</td>
                    <td>
                        <div
                            className="amount-data content-position">{(item.loadedWh / 1000).toFixed(2)} kWh
                        </div>
                    </td>
                    <td>
                        <div
                            className="content-position price-data">{item.amount} €
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        return (
            <div className="row">
                <div className="col-12 d-flex">
                    <div className="col-3 pre-content text-center">
                        <div>
                            <img src={PaymentHeadingLogo} alt=""/>
                            <h3 className="payments-heading">PAYMENTS</h3>
                        </div>
                        {haveDataToRender && <div className="mt-5">
                            <p>Time Period</p>
                            <div>
                                <DatePicker
                                    dayPlaceholder="FROM"
                                    monthPlaceholder=""
                                    yearPlaceholder=""
                                    clearIcon={this.state.dateFrom === '' ? null :
                                        <i className="fas fa-times"></i>}
                                    onChange={(event) => {
                                        this.handleDateChange(event, 'dateFrom')
                                    }}
                                    format="dd-MM-yyyy"

                                    className="input-date" calendarIcon={<img src={Calendar} alt=""/>}
                                    value={this.state.dateFrom}
                                />
                            </div>
                            <br/>
                            <DatePicker
                                dayPlaceholder="TO"
                                monthPlaceholder=""
                                yearPlaceholder=""
                                format="dd-MM-yyyy"
                                clearIcon={this.state.dateTo === '' ? null :
                                    <i className="fas fa-times"></i>}
                                className="input-date" calendarIcon={<img src={Calendar} alt=""/>}
                                value={this.state.dateTo}
                                placeholderText="TO"
                                onChange={(event) => {
                                    this.handleDateChange(event, 'dateTo')
                                }}/>
                        </div>}
                    </div>

                    {(() => {
                        if (this.state.loading) {
                            return (
                                <div className="col-10 mt-5 text-center">
                                    <Spinner animation={"border"} variant={"primary"}/>
                                </div>
                            )
                        } else {
                            return (
                                <div className="col-10 mt-5 text-center">
                                    <div className="row ">
                                        <div className="col-10 m-auto">
                                            {(() => {
                                                if (haveDataToRender) {
                                                    return dataTable
                                                } else {
                                                    return <h1 className="no-data-to-display">NO SESSIONS FOUND</h1>
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })()}

                </div>
            </div>

        );
    }
}

export default UserSessions;