import React, {Component} from 'react';
import PreferencesIcon from "../../assets/prefs-precontent-heading.svg";
import CameraIcon from '../../assets/camera.svg';
import BinIcon from '../../assets/trashh.png';
import './Preferences.css'
import {backendCommunicator} from "../../utils/backend";
import {getLocalImage} from "../../utils/avatarsHandler";
import InputField from "./InputField/InputField";
import InfoIcon from "../../assets/car-info.svg"
import UpdateButton from "../common/UpdateButton/UpdateButton";
import ToggleSwitch from "../common/ToggleSwitch/ToggleSwitch";
import DeleteButton from "./DeleteButton/DeleteButton";
import EditImageButton from "./EditImageButton/EditImageButton";
import Chevron from "./Chevron/Chevron";
import ChargeValueInput from "./ChargeValueInput/ChargeValueInput";
import CarDeleteConfirmModal from './CarDeleteConfirmModal/CarDeleteConfirmModal'
import IndicatorDots from "./IndicatorDots/IndicatorDots";
import {Spinner} from "react-bootstrap";
import DefaultCar from '../../assets/default-car.png';
import config from "../../config";

class Preferences extends Component {
    state = {
        cars: {},
        currentCar: -1,
        showDeleteConfirm: false,
        loading: false,
        imageLoading: false
    };

    async componentDidMount() {
        this.props.onActiveLinkChange(config.pageNames.PREFERENCES)
        this.setState({
            loading: true
        });
        await this.loadCars();
    }

    async loadCars() {
        let cars = await backendCommunicator.get('/profile/car');

        for (let car of cars.data) {
            await this.attachCarImage(car);
        }

        if (cars.data.length > 0) {
            /* Checking if the value of currentCar, if it is different than -1, it value will not be changed.
               This is needed for displaying correct dot indicator for the car that is currently displayed */
            if (this.state.currentCar === -1) {
                this.setState({
                    currentCar: 0,
                });
            }
            this.setState({
                cars: cars.data,
            });

        }
        this.setState({
            loading: false,
            imageLoading: false
        })
        if (!this.state.loading) {
            this.makeFirstDotActive()
        }

    }

    async attachCarImage(car) {
        let image = await getLocalImage(car.licensePlate, `/profile/car/picture/${car.id}`);
        car.image = image;
        return car;
    }

    handleDisplayedCarChange = (event) => {
        event.preventDefault();
        switch (event.target.name) {
            case 'prev-car':
                document.getElementById(`indicator-${this.state.currentCar + 1}`).removeAttribute('class')
                this.setState({currentCar: this.state.currentCar - 1});
                document.getElementById(`indicator-${this.state.currentCar}`).setAttribute('class', 'active')

                break;
            case 'next-car':
                this.setState({currentCar: this.state.currentCar + 1});
                document.getElementById(`indicator-${this.state.currentCar + 2}`).setAttribute('class', 'active')
                document.getElementById(`indicator-${this.state.currentCar + 1}`).removeAttribute('class')
                break;
            default:
                break;
        }
    };

    handleEditCarRequest = (index) => {
        let data = this.state.cars[index];

        let dataToPass = {
            id: data.id,
            autoCharge: data.autoCharge,
            targetCharge: data.targetCharge
        };

        backendCommunicator.put('/profile/car', dataToPass).then(res => {
            this.loadCars();
        }).catch(err => {
            console.log(err)
        })
    };

    handleCarDataChange = (event, index) => {
        const cars = this.state.cars;
        let fieldName = event.target.name;

        if (fieldName === "autoCharge") {
            cars[index].autoCharge = event.target.checked
        } else {
            let fieldValue = event.target.value;
            if (fieldValue < 0 || fieldValue > 100) {
                fieldValue = 80
            }
            cars[index].targetCharge = fieldValue
        }

        this.setState({
            cars: cars
        });
    };

    handleImageChange = (e, carId, license) => {
        e.preventDefault();
        this.setState({
            imageLoading: true
        });

        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('size', e.target.files[0].size);

        backendCommunicator.put(`/profile/car/picture/${carId}`, formData).then(res => {
            localStorage.removeItem(license);
            this.loadCars();

        }).catch(err => {
            console.log(err);
            this.setState({
                imageLoading: false
            });
        })
    };

    handleCarDeletion = (carId) => {
        backendCommunicator.delete(`/profile/car/${carId}`).then(res => {
            this.loadCars();
            this.openAndCloseDeleteConfirmModal();
        }).catch(err => {
            console.log(err)
        })
    };

    openAndCloseDeleteConfirmModal = () => {
        this.setState({showDeleteConfirm: !this.state.showDeleteConfirm})
    };

    makeFirstDotActive = () => {
        document.getElementById(`indicator-${this.state.currentCar + 1}`).setAttribute('class', 'active')
    };

    handleInputReset = (event) => {
        event.target.value = ''
    };

    showCarImage = (image) => {
        if (image !== 'data:image/png;base64,') {
            return image
        } else {
            return DefaultCar
        }
    };

    render() {
        const cars = this.state.cars;
        const currentCarIndex = this.state.currentCar;
        const haveDataToRender = cars.length > 0;
        return (
            <div className="col-12 d-flex pl-0">
                <div className="col-3 pre-content text-center">
                    <img src={PreferencesIcon} alt=""/>
                    <h3 className="payments-heading">PREFERENCES</h3>
                    {haveDataToRender && <div className="edit-delete-links">

                        <EditImageButton
                            onChange={(e) => this.handleImageChange(e, cars[currentCarIndex].id, cars[currentCarIndex].licensePlate)}
                            icon={CameraIcon}/>
                        <DeleteButton onClick={this.openAndCloseDeleteConfirmModal} icon={BinIcon}/>
                    </div>}
                </div>
                <div className="col-9 text-center mt-2 ml-5 pl-5">
                    {(() => {
                        if (this.state.loading) {
                          return  <div className="mt-5"><Spinner animation={"border"} variant={"primary"}/></div>
                        } else {
                            if (haveDataToRender) {
                                return <div>
                                    <div className="row pt-0">
                                        <div className="col-12">
                                            <div>
                                                {this.state.imageLoading ?
                                                    <Spinner animation={"border"} variant={"primary"}/> :
                                                    <img className="car-image-wrapper"
                                                         src={this.showCarImage(cars[currentCarIndex].image)} alt=""/>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 d-flex justify-content-center">

                                            <Chevron direction="left" className="prev-car" name="prev-car"
                                                     isDisabled={currentCarIndex === 0}
                                                     onClick={this.handleDisplayedCarChange}/>

                                            <div className="col-8 car-data">
                                                <div className="car-name">{cars[currentCarIndex].carName}</div>
                                                <div className="license-plate">{cars[currentCarIndex].licensePlate}</div>
                                            </div>

                                            <Chevron direction="right" className="next-car" name="next-car"
                                                     isDisabled={currentCarIndex === cars.length - 1}
                                                     onClick={this.handleDisplayedCarChange}/>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <IndicatorDots cars={cars}/>
                                    </div>
                                    <div className="row car-inputs-wrapper">
                                        <div className="col-10 car-inputs">
                                            <InputField label="Auto Charge" icon={InfoIcon}>
                                                <div className="">
                                                    <ToggleSwitch
                                                        onChange={(event) => this.handleCarDataChange(event, currentCarIndex)}
                                                        defaultValue={cars[currentCarIndex].autoCharge}
                                                        name="autoCharge"
                                                    />
                                                </div>
                                            </InputField>
                                            <InputField label="Default charging value">
                                                <ChargeValueInput onChange={(event) => {
                                                    this.handleCarDataChange(event, currentCarIndex)
                                                }}
                                                                  onBlur={(event) => this.handleInputReset(event)}
                                                                  targetCharge={cars[currentCarIndex].targetCharge}
                                                />

                                            </InputField>
                                            <InputField label="Number of charges">
                                                <p>{cars[currentCarIndex].numberOfCharges}</p>
                                            </InputField>
                                            <InputField label="Overall kWh">
                                                <p> {cars[currentCarIndex].overallkWh}</p>
                                            </InputField>

                                        </div>
                                        <div className="col-8 text-center">
                                            <UpdateButton onClick={() => {
                                                this.handleEditCarRequest(currentCarIndex)
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            } else {
                                return (
                                    <div>
                                        <h1 className="no-data-to-display">NO REGISTERED CARS</h1>
                                    </div>
                                )
                            }

                        }
                    })()}


                </div>

                <CarDeleteConfirmModal onDeletionConfirm={() => this.handleCarDeletion(cars[currentCarIndex].id)}
                                       isVisible={this.state.showDeleteConfirm}
                                       onClose={this.openAndCloseDeleteConfirmModal}
                />
            </div>
        );
    }
}

export default Preferences;