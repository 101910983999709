import React from 'react';
import PropTypes from 'prop-types';
import ShadowShape from "../../../assets/elipse.png";
import SeparatorLine from "../../../assets/LINE.svg";
import PlusIcon from "../../../assets/ADDICON.svg";

const Plan = ({planName, energyPrice, planPrice, onClick, icon}) => {

    return (
        <div className="plans plan-silver ">
            <div className={`${planName.toLowerCase()}-frame-wrapper`}>
                <div className={`text-center icon-star ${planName === 'Platinum' ? 'icon-star-plat' : null }`} >
                    <img src={icon} alt=""/>
                </div>
                <p className="plan-name">{planName}</p>
            </div>
            <div className="shape">
                <img src={ShadowShape} alt=""/>
            </div>
            <div className="text-center mt-3 price-with-plan">
                <p className="pref-price"> {energyPrice}
                    <span className="pref-price-euro-sign">
                        €/kWh
                    </span>
                </p>
                <p className="green-energy-text">GREEN ENERGY</p>
            </div>
            <div className="text-center">
                <img src={SeparatorLine} alt=""/>
            </div>
            <div className="text-center">
                <p className="plan-price"> {planPrice} <span
                    className="plan-price-euro-sign"> €</span></p>
            </div>
            <div className="subscribe-btn-wrapper text-center">
                <button onClick={onClick} className="subscribe-btn">
                    <img src={PlusIcon} alt=""/>
                    <p className="subscribe-btn-text pt-1">SUBSCRIBE</p>
                </button>
            </div>
        </div>
    );
};

Plan.propTypes = {
    planName: PropTypes.string,
    energyPrice: PropTypes.string,
    planPrice: PropTypes.number,
    onClick: PropTypes.func,
    icon: PropTypes.string
};

export default Plan;