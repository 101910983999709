import axios from 'axios';
import sessionStorageService from "../services/storageService/LocalStorageService";


const baseUrl = process.env.REACT_APP_BACKEND_URL;

export const backendCommunicator = axios.create({
    baseURL: baseUrl
});

export const userAuthRequest = axios.create({
    baseURL: baseUrl
});

function refreshToken() {

    return backendCommunicator.post('/user/refresh', {}, {
        headers: {
            Refresh: sessionStorageService.getRefreshToken()
        }
    })
        .then(res => {
            let tokens = {
                accessToken: res.headers.authorization,
                refresh: res.headers.refresh
            };
            sessionStorageService.setTokens(tokens);
            window.location.reload()
        }).catch(err => {
            sessionStorage.clear();
            window.location.href = '/'
        })
}

backendCommunicator.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return refreshToken()
    }
    return Promise.reject(error);
});

backendCommunicator.interceptors.request.use(function (config) {
        let token = sessionStorageService.getAccessToken();

        if (token !== null) {
            config.headers['Authorization'] = token;
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
);