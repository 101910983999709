import React from 'react';
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";

const InputField = props => {
    return (
        <div className="d-flex m-auto pt-2 pb-2">
            <div className="col-4 text-left">
                <p>{props.label}   {props.icon ? <span className="pl-2"><img src={props.icon} alt=""/></span> : null}</p>
            </div>

           <div className="col-3 offset-2 text-right">
               {props.children}
           </div>
        </div>
    );
};

InputField.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
};

export default InputField;