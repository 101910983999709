import React from 'react';
import PropTypes from 'prop-types';
import './ChargeType.css';

const ChargeType = ({icon, name}) => {
    return (
        <div className="ct-wrapper">
            <div className="ct-icon-wrapper">
                <img src={icon} alt=""/>
            </div>
            <p className="ct-text">{name}</p>
        </div>
    );
};

ChargeType.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string
};

export default ChargeType;