import React from 'react';
import PropTypes from 'prop-types';
import './RenewalSwitch.css';

const RenewalSwitch = ({defaultValue, onChange, isDisabled}) => {
    return (
        <div className="onoffswitch m-auto">
            <input type="checkbox" disabled={isDisabled} name="onoffswitch" onChange={onChange} defaultChecked={defaultValue} className="onoffswitch-checkbox" id="myonoffswitch"/>
            <label className="onoffswitch-label" htmlFor="myonoffswitch">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
            </label>
        </div>
    );
};

RenewalSwitch.propTypes = {
    defaultValue: PropTypes.bool,
    isDisabled: PropTypes.bool
};

export default RenewalSwitch;