import React from 'react';
import PropTypes from 'prop-types';

const DeleteButton = ({onClick, icon }) => {
    return (
        <button onClick={() => {
            onClick()
        }} className="remove-vehicle">
            <img src={icon} alt=""/>
            <span> Remove this vehicle ?</span>
        </button>
    );
};

DeleteButton.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string
};

export default DeleteButton;