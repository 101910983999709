//Checks the localstorage for existing image by identifier and returns it.
//If not present will fetch image from backend
import LocalStorageService from "../services/storageService/LocalStorageService";

export async function getLocalImage(identifier, backendResource) {
    let binaryPhoto;

    if (localStorage.getItem(identifier)) {
        binaryPhoto = localStorage.getItem(identifier);
    } else {
        let response = await fetch(process.env.REACT_APP_BACKEND_URL + backendResource, {
            method: 'GET',
            headers: {
                'Authorization': LocalStorageService.getAccessToken()
            },
        })

        let binaryResponse = await response.arrayBuffer();

        binaryPhoto = `data:image/png;base64,${new Buffer(binaryResponse).toString('base64')}`

        localStorage.setItem(identifier, binaryPhoto)
    }

    return binaryPhoto;
}