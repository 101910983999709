import React from 'react';
import PropTypes from 'prop-types';

const EditImageButton = ({carId, onChange, icon}) => {
    return (
        <span>
            <input onChange={(e) => {
                onChange(e, carId)
            }} type="file" name="file" id="file" className="inputfile"/>
            <label className="edit-vehicle" htmlFor="file"><img src={icon} alt=""/> Edit vehicle image</label>
      </span>
    );
};

EditImageButton.propTypes = {
    carId: PropTypes.string,
    onChange: PropTypes.func,
    icon: PropTypes.string
};

export default EditImageButton;