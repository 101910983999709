import React from 'react';
import PropTypes from 'prop-types';
import './SocialLoginButton.css';

const SocialLoginButton = ({icon, isGoogle, onClick}) => {
    return (
        <button onClick={onClick} className={isGoogle ? 'socialButtonGoogle' : 'socialButton'}>
            <img src={icon} alt=""/>
        </button>
    );
};

SocialLoginButton.propTypes = {
    icon: PropTypes.string,
    isGoogle: PropTypes.bool,
    onClick: PropTypes.func

};

export default SocialLoginButton;