import React from 'react';
import PropTypes from 'prop-types';
import './InfoBox.css';


const InfoBox = ({headingText, icon}) => {
    return (
        <div className="col-lg-3 col-sm-12 text-center box-wrapper">
            <div className="col-12 box-heading text-center">
                <div className="col-6 text-center m-auto">
                    {headingText.split(' ')[0]}
                    <p>{headingText.split(' ')[1]}</p>
                </div>
            </div>
            <div className="mt-5 icon-wrapper">
                <img src={icon} alt=""/>
            </div>
            <div className="learn-more-btn-wrapper">
                <button className="learn-more-btn">
                    LEARN MORE
                </button>
            </div>
        </div>
    );
};

InfoBox.propTypes = {
    icon: PropTypes.string,
    headingText: PropTypes.string
};

export default InfoBox;