import React from 'react';
import GoogleLogin from 'react-google-login';
import SocialLoginButton from "../SocialButton/SocialLoginButton";
import GoogleIcon from '../../../assets/Google_-G-_Logo.svg.png';
import {userAuthRequest, backendCommunicator, loginRequest} from "../../../utils/backend";
import PropTypes from "prop-types";
import UserDataService from "../../../services/storageService/UserDataService";
import SessionstorageService from "../../../services/storageService/LocalStorageService";
import {loginResponseHandler} from "../../../services/UserService/UserService";

const GoogleLoginButton = props => {
    const googleResponse = (response) => {

        userAuthRequest.post('/user/login/google', {
            "id": response.googleId,
            "accessToken": response.tokenId
        }).then(res => {
            loginResponseHandler(res);
            props.history.push('/user/payments')
        }).catch(err => console.log(err))
    };

    const onFailure = () => {
        console.log('Failed')
    };

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_API_KEY}
            onSuccess={googleResponse}
            onFailure={onFailure}
            render={renderProps => (
                <SocialLoginButton icon={GoogleIcon} isGoogle={true} onClick={renderProps.onClick}/>

            )}
        />
    );
};

GoogleLoginButton.propTypes = {
    history: PropTypes.object
};


export default GoogleLoginButton;