import React from 'react';
import PropTypes from 'prop-types';
import './AccessPointSection.css';
import ChademoPlug from '../../../assets/Chademo plug.png';
import CCSPlug from '../../../assets/CCS plug.png';
import TeslaPlug from '../../../assets/tesla.png';
import ChargeType from "./ChargeType/ChargeType";

const AccessPointSection = props => {
    return (
        <div className="col-12 section-wrapper ">
            <div className="col-6 section-heading  m-auto text-center">
                MULTIPLE ACCESS POINTS
                GUARANTEED POWER
            </div>
            <div className="col-4 m-auto text-center section-text">
                <p>Get a 100km charge in just 15 minutes for any electric vehicle type or charging standard.</p>
            </div>
            <div className="read-more-btn-wrapper text-center">
                <button className="read-more-btn">
                    READ MORE
                </button>

            </div>
            <div className="float-right pb-5 text-center charge-types d-flex">
                <ChargeType name="Tesla" icon={TeslaPlug}/>
                <ChargeType name="CCS" icon={CCSPlug}/>
                <ChargeType name="CHAdeMO" icon={ChademoPlug}/>
            </div>
        </div>
    );
};

AccessPointSection.propTypes = {};

export default AccessPointSection;