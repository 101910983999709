import React from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.css';

const ToggleSwitch = ({defaultValue, onChange, isDisabled, name}) => {
    return (
        <div className="onoffswitch m-auto">
            <input type="checkbox" disabled={isDisabled} name={name} onChange={onChange} checked={defaultValue} className="onoffswitch-checkbox" id="myonoffswitch"/>
            <label className="onoffswitch-label" htmlFor="myonoffswitch">
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
            </label>
        </div>
    );
};

ToggleSwitch.propTypes = {
    defaultValue: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string
};

export default ToggleSwitch;