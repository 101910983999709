import React, {useState} from 'react';
import PropTypes from 'prop-types';
import chev from '../../assets/chevron-down-solid.svg'
import languages from './languages';

const LanguageDropdown = ({label, nameAndId}) => {
    const [language, setLanguage] = useState('English');
    const handleLanguageChange = (event, name) => {
        event.preventDefault()
        return setLanguage(name)
    };

    const handleIcon = (icon) => {
        let flag = require(`../../assets/${icon}`)
        return flag
    };

    return (
        <div className="col-12 d-flex  p-3 select-wrapper">
            <label className="labels col-3" htmlFor={nameAndId}>{label}</label>

            <div className="p-0 dropdown lang-dropdown">
                <button className="lang-btn" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                    {Object.values(languages).map(lang => {
                        if (language === lang.name) {
                            return (<span key={lang.name} className="flags-dd">
                                <img src={handleIcon(lang.icon)} alt=""/> {lang.name}
                                <img className="float-right" src={chev} alt=""/>
                            </span>)
                        }
                    })}
                </button>
                <div className="dropdown-menu lang-dd" aria-labelledby="dropdownMenuButton">
                    {Object.values(languages).map(lang => (
                        <button onClick={(event) => handleLanguageChange(event, lang.name)} name={lang.code}
                                className="dropdown-item flags-dd">
                            <img src={handleIcon(lang.icon)} alt=""/> {lang.name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

LanguageDropdown.propTypes = {
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    nameAndId: PropTypes.string,
    onChange: PropTypes.func
};

export default LanguageDropdown;