import React from 'react';
import './ChargeInfoSection.css';
import PropTypes from 'prop-types';
import CoffeeImage from '../../../assets/coffee.png';
import BikeImage from '../../../assets/bikee.png'
import ChargeInfoItem from "./ChargeInfoItem/ChargeInfoItem";

const ChargeInfoSection = props => {
    return (
        <div className="cis-wrapper d-flex ">
            <ChargeInfoItem image={CoffeeImage} heading={"Comfortable Charging Experience"} customClass=""/>
            <ChargeInfoItem image={BikeImage} heading={`Free E-Bike Charging`} customClass={'greyBgr'}/>
        </div>
    );
};

ChargeInfoSection.propTypes = {};

export default ChargeInfoSection;