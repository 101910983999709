import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function notify(type,heading, message ) {
    switch (type) {
        case 'info':
            NotificationManager.info(message, heading, 4000);
            break;
        case 'success':
            NotificationManager.success(message, heading, 4000);
            break;
        case 'warning':
            NotificationManager.warning(message, heading, 4000);
            break;
        case 'error':
            NotificationManager.error(message, heading, 4000);
            break;
        default:
            break;
    }
}

export default {
    notify
};
