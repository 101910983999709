import React from 'react';
import {Elements} from 'react-stripe-elements'
import PaymentRequestForm from "./PaymentRequestForm";
import {userAuthRequest} from "../../../utils/backend";
import HeaderImage from '../../../assets/HEADER.png';
import './StripePayments.css'

class StripePayments extends React.Component {
    state = {
        cardId: '',
        token: '',
        planId: '',
        plan: {},
        clientSecret: ''
    };

    componentDidMount() {
        const regex = /token=(?<token>\w+)\&cardId=(?<cardId>-?\w+)\&planId=(?<planId>\w+)/;
        let match = regex.exec(window.location.href);
        if (match) {
            let {groups: {token, planId, cardId}} = match;

            this.setState({
                cardId: cardId,
                token: token,
                planId: planId
            });
            userAuthRequest.get(`/payment/web/jwt?token=${token}`).then(res => {

                sessionStorage.setItem('authtoken', res.headers.authorization)
                sessionStorage.setItem('refresh', res.headers.refresh)

                userAuthRequest.get('/plan', {
                    'Authorization': res.headers.authorization
                }).then(response => {
                    response.data.map(plan => {
                        if (plan.id === Number(planId)) {
                            this.getClientSecret(sessionStorage.getItem('authtoken'), plan)
                                .then(res => {
                                    this.setState({
                                        clientSecret: res.data
                                    })
                                }).catch(err => console.log(err));
                            this.setState({
                                plan: plan
                            });
                        }
                    });

                }).catch(err => console.log('error in getting plans'))
            }).catch(err => {
                console.log('error in getting jwt')
            });
        }
    }

    getClientSecret = (auth, data) => {
        return userAuthRequest.post('/payment/secret', {
            amount: data.initialAmount,
            currency: 'eur',
            planId: data.planId
        }, {
            headers: {
                authorization: auth
            }
        });
    };

    render() {
        return (
            <Elements>
                <div className="">
                    <div className="col-lg-4 col-12 m-auto p-0">
                        <img className="header-img" src={HeaderImage} alt=""/>
                    </div>
                    <div>
                        {this.state.clientSecret &&  <PaymentRequestForm clientSecret={this.state.clientSecret} cardId={this.state.cardId}
                                                                         onPaymentComplete={this.props.onPaymentComplete} plan={this.state.plan}/>}

                    </div>
                </div>
            </Elements>
        )
    };
}

StripePayments.propTypes = {};

export default StripePayments;