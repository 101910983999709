import React, {Component} from 'react';
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";
import UserSessions from "../UserSessions/UserSessions";
import UserProfile from "../UserProfile/UserProfile";
import VerticalNavbar from "../VerticalNavbar/VerticalNavbar";
import MySubscription from "../MySubscription/MySubscription";
import Preferences from "../Preferences/Preferences";
import config from "../../config";
import {Switch, Route} from "react-router-dom";
import UserDataService from "../../services/storageService/UserDataService";

class LoggedUserMainPage extends Component {
    state = {
        activePage: '',
        userData: {}
    };

    componentDidMount() {
        if (!sessionStorage.getItem('active')) {
            sessionStorage.setItem('active', config.pageNames.PAYMENTS)

            this.setState({
                activePage:   sessionStorage.getItem('active')
            })
        }
    }


    setActivePage = (page) => {
        this.setState({
            activePage:    sessionStorage.getItem('active')
        })
        sessionStorage.setItem('active', page)
    };

    handleUserDataChange = (data) => {
        let userData = UserDataService.getUserData();
        userData.firstName = data.firstName
        UserDataService.setUserData(userData)
        this.setState({
            userData: data
        })

    };

    render() {
        return (
            <div>
                <NavigationBar/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-2 p-0">
                            <VerticalNavbar onLinkClick={this.setActivePage}/>
                        </div>
                        <div className="col-9 p-0" >
                            <Switch>
                                <Route exact path="/user/profile" render={(props) => <UserProfile {...props} onActiveLinkChange={this.setActivePage} />} />
                                <Route exact path="/user/payments" render={(props) => <UserSessions {...props} onActiveLinkChange={this.setActivePage} />}/>
                                <Route exact path="/user/subscription" render={(props) => <MySubscription {...props} onActiveLinkChange={this.setActivePage} />} />
                                <Route exact path="/user/preferences" render={(props) => <Preferences {...props} onActiveLinkChange={this.setActivePage} />} />
                            </Switch>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default LoggedUserMainPage;