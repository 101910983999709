import React from 'react';
import PropTypes from 'prop-types';
import './UpdateButton.css'

const UpdateButton = ({disabled, onClick}) => {
    return (
       <button disabled={disabled} className="profile-update-btn" onClick={onClick}> UPDATE </button>
    );
};

UpdateButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default UpdateButton;