import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import './LoginForm.css';
import MailIcon from '../../assets/email_icon.svg';
import PasswordIcon from '../../assets/password_icon.svg';
import CreateUserIcon from '../../assets/Shape 834.svg';
import GoogleLoginButton from './GoogleLogin/GoogleLogin';
import FacebookLoginButton from "./FacebookLogin/FacebookLoginButton";
import TwitterLoginButton from "./TwitterLogin/TwitterLoginButton";
import {userAuthRequest} from "../../utils/backend";
import UserDataService from "../../services/storageService/UserDataService";
import sessionStorageService from "../../services/storageService/LocalStorageService";

class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        loading: false,
        hasError: false,
        loginInvalid: false,
        error: '',
        userData: {}
    };

    handleInputChange = event => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        this.setState({[fieldName]: fieldValue})
    };

    handleLoginFormSubmit = event => {
        event.preventDefault();
        this.setState({loading: true});

        userAuthRequest.post('/user/login', {
            "email": this.state.email,
            "password": this.state.password
        })
            .then(response => {
                this.setState({loading: false, userData: response.data});
                UserDataService.setUserData(response.data);
                let tokens = {
                    accessToken:  response.headers.authorization,
                    refresh: response.headers.refresh
                };
                sessionStorageService.setTokens(tokens);
                this.props.history.push('/user/payments')
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error:  "Wrong user and password combination"});
            })
    };

    enterPressed = (event) => {
        if (event.key === 'Enter') {
            this.handleLoginFormSubmit(event)
        }
    };

    render() {
        return (
            <div className="col-12 m-auto form-bgr">
                <div className="row">
                    <div className="col-12 m-auto nexton-background">
                        <div className="m-auto text-center pt-5">
                            <p className="styledText pt-2"><b>LOG IN WITH</b></p>
                        </div>
                    </div>
                </div>
                <div className="row socials">
                    <div className="col-12 text-center m-auto">
                    <span className="">
                       <GoogleLoginButton history={this.props.history}/>
                    </span>
                        <span className="">
                       <FacebookLoginButton history={this.props.history}/>
                    </span>
                        <span className="">
                        <TwitterLoginButton history={this.propshistory}/>
                    </span>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 m-auto">
                        <form action="" onKeyPress={this.enterPressed}>
                            <div className="input-group inputBottomLine">
                                <div className="input-group-prepend">
                                    <span className="imgSpan mailIcon"> <img className="" src={MailIcon} alt=""/></span>
                                </div>
                                <input onChange={this.handleInputChange} type="email" name="email" className="form-control inputField"
                                       placeholder="Your email"/>
                            </div>
                            <div className="input-group inputBottomLine mt-3">
                                <div className="input-group-prepend">
                                    <span className="imgSpan"> <img className="" src={PasswordIcon} alt=""/></span>
                                </div>
                                <input onChange={this.handleInputChange} type="password" name="password"
                                       className="form-control inputField" placeholder="Password"/>
                            </div>
                        </form>
                        {this.state.hasError ? <div className="alert alert-danger" role="alert">{this.state.error}</div> : null}

                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 m-auto">
                    <span className="loginSpan"> <button onClick={this.handleLoginFormSubmit} type="submit"
                                                         className="loginButton"> LOGIN </button></span>
                        <div className="forgottenPasswordWrapper">
                            <Link to="/forgottenPassword" className="forgottenPasswordLink">
                                Forgot Password ?
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 p-0">
                        <div onClick={this.props.onRegisterClick} style={{cursor: 'pointer'}}
                             className="createUser d-flex  text-center">
                            <div className="pt-2 createText m-auto p-3"><img src={CreateUserIcon} alt=""/>
                                <b>CREATE NEW ACCOUNT</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

LoginForm.propTypes = {
    history: PropTypes.object,
    onRegisterClick: PropTypes.func

};

export default LoginForm;