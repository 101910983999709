function setTokens(tokens) {
    localStorage.setItem('authtoken', tokens.accessToken);
    localStorage.setItem('refresh', tokens.refresh)
}

function getAccessToken() {
   return localStorage.getItem('authtoken');
}

function getRefreshToken() {
    return localStorage.getItem('refresh');
}

function deleteTokens() {
    localStorage.removeItem('authtoken');
    localStorage.removeItem(('refresh'));
}

export default {
    setTokens,
    getAccessToken,
    getRefreshToken,
    deleteTokens
}
