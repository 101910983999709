import XRegExp from 'xregexp';

const pageNames = {
    PROFILE: "profile",
    PAYMENTS: "payments",
    MY_SUBSCRIPTION: "subscription",
    PREFERENCES: "preferences"
};


//Validators
const validPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const validEmailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
const validNameRegex = XRegExp('\\p{L}+');
const twitterOauthParameters = /\?oauth_token=(?<oauth_token>.+?)&oauth_verifier=(?<oauth_verifier>.+)/;


export default {
    validators: {
        validPasswordRegex,
        validEmailRegex,
        validNameRegex,
        twitterOauthParameters,

    },
    pageNames
}
