import React from 'react';
import CreateUserIcon from "../../assets/Shape 834.svg";
import '../LoginForm/LoginForm.css';
import {userAuthRequest} from "../../utils/backend";
import NotificationsService from "../../services/NotificationService/NotificationService";
import {Spinner} from "react-bootstrap";
import PropTypes from 'prop-types';
import LoginForm from "../LoginForm/LoginForm";

class RegistrationForm extends React.Component {
    state = {
        passwordsDoesntMatch: false,
        loading: false,
        error: null

    };

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };


    handleRegistrationRequest = (event) => {
        this.setState({loading: true, error: null});

        if (this.state.password === this.state.retypePassword) {
            this.setState({passwordsDoesntMatch: false});

            userAuthRequest.post('/user', {
                email: this.state.email,
                password: this.state.password,
                firstName: this.state.firstName,
                lastName: this.state.lastName
            }).then(res => {
                this.setState({loading: false, })

                NotificationsService.notify("success", "Registration successful.", "We have sent an email with an activation link to your email address.");
                this.props.onRegistrationComplete()

            }).catch(err => {
                this.setState({loading: false, error: err.response.status})
            })
        } else {
            this.setState({passwordsDoesntMatch: true, loading: false})
        }
    };

    displayError = () => {
        let error = this.state.error;
        if (error === 400) {
            return <div className="alert alert-danger mt-4 text-center" role="alert">Bad input data.</div>
        } else if (error === 409 ) {
            return  <div className="alert alert-danger mt-4 text-center" role="alert">Email already exists.</div>
        }
        if (this.state.passwordsDoesntMatch) {
            return   <div className="alert alert-danger mt-4 text-center" role="alert">Passwords doesn't match.</div>
        }
    };

    enterPressed = (event) => {
        if (event.key === 'Enter') {
            return this.handleRegistrationRequest()
        }
    };

    render() {
        return (
            <div className="col-12 m-auto form-bgr">
                <div className="row">
                    <div className="col-12 m-auto nexton-background">
                        <div className="m-auto text-center pt-5">
                            <p className="styledText pt-2"><b> REGISTER ACCOUNT</b></p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 m-auto">
                        <form action="" onKeyPress={this.enterPressed}>
                            <div className="input-group inputBottomLine">

                                <input onChange={this.handleInputChange} type="email" name="email"
                                       className="form-control inputField"
                                       placeholder="Your email"/>
                            </div>
                            <div className="input-group inputBottomLine mt-3">

                                <input onChange={this.handleInputChange} type="password" name="password"
                                       className="form-control inputField" placeholder="Password"/>
                            </div>
                            <div className="input-group inputBottomLine mt-3">

                                <input onChange={this.handleInputChange} type="password" name="retypePassword"
                                       className="form-control inputField" placeholder="Retype Password"/>
                            </div>
                            <div className="input-group inputBottomLine mt-3">

                                <input onChange={this.handleInputChange} type="text" name="firstName"
                                       className="form-control inputField" placeholder="First Name"/>
                            </div>
                            <div className="input-group inputBottomLine mt-3">

                                <input onChange={this.handleInputChange} type="text" name="lastName"
                                       className="form-control inputField" placeholder="Last Name"/>
                            </div>
                        </form>

                        {this.displayError()}
                        {this.state.loading &&
                        <div className="text-center pt-3 pb-3"><Spinner animation="border" variant="info"/></div>}

                    </div>

                </div>
                <div className="row">
                    <div className="col-12 mt-2 p-0">
                        <button onClick={this.handleRegistrationRequest} style={{cursor: 'pointer'}}
                                className="createUser d-flex  text-center">
                            <div className="pt-2 createText m-auto p-3"><img src={CreateUserIcon} alt=""/>
                                <b>SIGN UP</b>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

RegistrationForm.propTypes = {
    onRegistrationComplete: PropTypes.func
};

export default RegistrationForm;