import React from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement
} from 'react-stripe-elements';
import './StripeAddCard.css'

const CardSection = () => {
    return (
        <div className="col-sm-12 col-lg-4 m-auto">
            <div className="col-6">
                Card Information
            </div>

            <div className="col-10 p-0 sc-wrapper m-auto">
                <div className="col-sm-12 num">
                    <CardNumberElement/>
                </div>
                <div className="col-sm-12 d-flex wrp">
                    <div className="col-sm-3 brd">
                        <CardCVCElement/>
                    </div>
                    <div className="col-sm-3 brd">
                        <CardExpiryElement/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSection;