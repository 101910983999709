const updateUserData = (key) => {
    let data = JSON.parse(localStorage.getItem('userData'));
    data[key] = !data[key];
    return localStorage.setItem('userData', JSON.stringify(data));
};

const setUserData = (data) => {
    return localStorage.setItem('userData', JSON.stringify(data));
};

const getUserData = () => {
    return JSON.parse(localStorage.getItem('userData'))
};

const removeUserData = () => {
    return localStorage.removeItem('userData')
};


export default {
    updateUserData,
    setUserData,
    getUserData,
    removeUserData
}