import React from 'react';
import PropTypes from 'prop-types';

const ChargeValueInput = ({targetCharge, onChange, onBlur}) => {
    return (
        <div>
           <div className="d-flex charge-input">
               <input type="number" list="chargingValues" min={0} max={100}
                      className="defaultChargingValue"
                      placeholder={`${targetCharge}%`}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={targetCharge}
                      id="chargingValue"
                      name="chargingValue"
               />

            <datalist id="chargingValues">
                <option>100</option>
                <option>90</option>
                <option>80</option>
                <option>70</option>
                <option>60</option>
            </datalist>
        </div>
        </div>
    );
};

ChargeValueInput.propTypes = {
    targetCharge: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};

export default ChargeValueInput;