import React from 'react';

const ChargeInfoItem = ({heading, image, customClass}) => {
    return (
        <div className={`col-6 p-0  ${customClass}`} >
            <div className="cis-heading m-auto">
                {heading.split(' ')[0]} {heading.split(' ')[1]} <br/>
                {heading.split(' ')[2]}
            </div>
            <div className="cis-text  text-center">
                <p className="m-auto">Get a 100km charge in just 15 minutes for any electric vehicle type or
                    charging standard.</p>
            </div>
            <div className="cis-image text-center">
                <img src={image} alt=""/>
            </div>

            <div className="cis-btn-wrapper text-center">
                <button className="cis-btn">
                    READ MORE
                </button>

            </div>
        </div>
    );
};

ChargeInfoItem.propTypes = {

};

export default ChargeInfoItem;