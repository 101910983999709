import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import './SubscriptionPlan.css'
import SingleStar from "../../../assets/STAR.png";
import DoubleStar from "../../../assets/DOUBLESTAR.png";
import TripleStar from "../../../assets/TRISTAR.png";

const SubscriptionPlan = ({ordinal, name}) => {


    const getAttributes = (ordinal) => {
        switch (ordinal) {
            case 1:
                return {
                    icon: SingleStar,
                    style: "Plan1"
                };
            case 2:
                return {
                    icon: DoubleStar,
                    style: "Plan2"
                };
            case 3:
                return {
                    icon: TripleStar,
                    style: "Plan3"
                };
            default:
                break;
        }
    };

    return (
        <Link to="/subscriptions" className={`ms-plan ${getAttributes(ordinal).style}`} >
            <div className={`msp-icon ${name === "Platinum" && "msp-icon-plat"}`}><img src={getAttributes(ordinal).icon} alt=""/></div>
            <div className="msp-name">{name}</div>
        </Link>
    );
};

SubscriptionPlan.propTypes = {
    ordinal: PropTypes.number,
    name: PropTypes.string
};

export default SubscriptionPlan;