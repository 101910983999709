import React from 'react';
import PropTypes from 'prop-types';

const IndicatorDots = cars => {
    let dotNumber = 0;
    return (
        <div className="col-12">
            <ol className="carousel-indicators">
                {cars.cars.map(item => (
                    <li key={dotNumber} className="" id={`indicator-${dotNumber += 1}`}> </li>
                ))}
            </ol>
        </div>
    );
};

IndicatorDots.propTypes = {
    cars: PropTypes.array
};

export default IndicatorDots;