import React from 'react';
import './CarouselSection.css';
import Image from '../../../assets/Layer 1.png';
import FacebookIcon from '../../../assets/facebook-icon-home.svg';
import TwitterIcon from '../../../assets/twitter-icon-home.svg';
import YouTubeIcon from '../../../assets/youtube-icon-home.svg';
import InstagramIcon from '../../../assets/instagram-icon-home.svg';
import MouseIcon from '../../../assets/mouse.svg'


const CarouselItem = () => {
    return (
        <div className="col-12 p-0">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>

                </ol>
                <span className="carousel-indicators">

                </span>
                <div className="carousel-inner">
                    <div className="carousel-item active">

                        <div className="slide-info-container">
                            <p className="slide-info-header">RAPID CHARGING</p>
                            <p className="slide-info-text">
                                Get a 100km charge in just 15 minutes for any electric vehicle type or charging
                                standard.
                            </p>
                            <div className="d-flex">
                                <div>
                                    <button className="btn-home-learnMore"> LEARN MORE</button>
                                </div>
                                <div className="home-scroll-down">
                                    <img src={MouseIcon} alt=""/>
                                    or scroll down
                                </div>

                            </div>
                        </div>
                        <img src={Image} alt=""/>

                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>

                </a>
            </div>
            <div className="socials-home">
                <span className="socials-home-text">Follow us on</span>
                <span className="socials-home-image"> <img src={FacebookIcon} alt=""/></span>
                <span className="socials-home-image"> <img src={TwitterIcon} alt=""/></span>
                <a href="https://www.youtube.com/channel/UCqNliinDyMe7ACYMBrqlZ8g" target="_blank" className="socials-home-image"> <img src={YouTubeIcon} alt=""/></a>
                <span className="socials-home-image"> <img src={InstagramIcon} alt=""/></span>
            </div>
        </div>
    );
};

export default CarouselItem;