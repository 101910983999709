import React from 'react';
import {Elements} from 'react-stripe-elements';
import PropTypes from "prop-types";
import InjectAddCard from './StripeAddCard'

const StripeAddCardHOC = props => {
    return (
        <Elements>
            <InjectAddCard onCardAdded={props.onCardAdded}/>
        </Elements>
    );
};

StripeAddCardHOC.propTypes = {
    onCardAdded: PropTypes.func,
};

export default StripeAddCardHOC;